import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MenuItem } from 'primeng/api';
import { Subject, map, shareReplay } from 'rxjs';
import { PrimengTranslationService } from '../../services/primeng-translation-service';
import { ThemeService } from '../../services/theme-service';
import { loadCompanies } from '../../../core/state/company/company-context.actions';
import { loadUser } from '../../../core/state/user/user.actions';
import { selectUser } from '../../../core/state/user/user.selectors';
import { User } from '../../../core/state/user/user.model';
import { use } from 'echarts';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrl: './hub.component.css'
})
export class HubComponent {
  title = 'ekowitryna-esq-calculator';


  @ViewChild('slidedContainer') slidedContainer!: ElementRef;

  menuItems: MenuItem[] = [];

  sidebarVisible = false; 
  sidebarWidth = 400;
  isResizing = false;
  isCollapsed = false; // State of the sidebar
  showToggleButton = false;
  scrolled = false;
  isInitializing = true;
  destroy$ = new Subject();
  user: User | null = null;

  
  readonly MIN_WIDTH = 25;
  readonly MIN_WIDTH_FOR_RESIZE = 200;
  readonly MAX_WIDTH = 400;


  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(
    private translate: TranslateService,
    private keycloak: KeycloakService,
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    public themeService: ThemeService,
    public primengTranslationService: PrimengTranslationService,
  ) {

    themeService.afterRefresh();
  }

  ngOnInit() {
    this.store.select(selectUser).subscribe(user => {
      this.user = user;
    });

    this.store.dispatch(loadUser());
  

    const sidebar = document.querySelector('.sidebar') as HTMLElement;

    this.menuItemsInit();

    this.store.dispatch(loadCompanies());
    
    const keycloakInstance = this.keycloak.getKeycloakInstance();

    keycloakInstance.onTokenExpired = () => {
      keycloakInstance.updateToken(30).then(() => {}).catch(() => {
        console.error('failed to get a new token');
      });

      keycloakInstance.onAuthError = (error: any) => {
        if (error.error === "invalid_token" || error.error === "access_denied") {
          console.log("Token wygasł lub jest nieprawidłowy. Przekierowanie do strony logowania.");
          keycloakInstance.logout();
        }
      };
    }

    this.isInitializing = false;


  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleRefreshData() {
    this.menuItemsInit();
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.sidebarWidth = this.isCollapsed ? this.MIN_WIDTH : this.MAX_WIDTH;


  }

  showToggleButtonFunction(){
    this.showToggleButton = true;

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isResizing) {
      // Calculate the new width
      let newWidth = event.clientX;

      // Enforce minimum and maximum bounds
      if (newWidth < this.MIN_WIDTH) {
        newWidth = this.MIN_WIDTH;
        this.isCollapsed = true;
      } else if (newWidth > this.MAX_WIDTH) {
        newWidth = this.MAX_WIDTH;
        this.isCollapsed = false;
      } else if(newWidth < this.MIN_WIDTH_FOR_RESIZE) {
        newWidth = this.MIN_WIDTH;
        this.isCollapsed = true;
      }

      // Update the sidebar width
      this.sidebarWidth = newWidth;


    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    if (this.isResizing) {
      this.isResizing = false;
    }
  }

  startResizing(event: MouseEvent) {
    event.preventDefault();
    this.isResizing = true;
  }

  temporarilyShowSidebar() {

    if (this.isCollapsed) {
      this.sidebarWidth = this.MAX_WIDTH;
    }
  }

  onMouseLeaveSidebar() {
    this.showToggleButton = this.isCollapsed;

    if (this.isCollapsed) {
      this.sidebarWidth = this.MIN_WIDTH;
    }
  }


  menuItemsInit(){

    this.translate.get([
      'views.app.menu.dashboard',
      'views.app.menu.scope1',
      'views.app.menu.scope1.vehicle',
      'views.app.menu.scope1.fugitiveEmission',
      'views.app.menu.scope1.stationaryDevice',
      'views.app.menu.scope2',
      'views.app.menu.scope2.heatConsumption',
      'views.app.menu.scope2.electricityConsumption',
      'views.app.menu.scope2.renewableEnergyConsumption',
      'views.app.menu.scope2.rweBought',
      'views.app.menu.scope2.certificatedRenewableEnergyBought',
      'views.app.menu.scope3',
      'views.app.menu.scope3.manualCompaniesCO2Score',
      'views.app.menu.scope3.conferences',
    ]).subscribe(translations => {

      this.menuItems = [
        {
          label: translations['views.app.menu.dashboard'],
          icon: 'fa-solid fa-house',
          routerLink: ['/dashboard'],
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: translations['views.app.menu.scope1'],
          icon: 'fa-solid fa-volcano',
          expanded: true,
          items: [
            {
              label: translations['views.app.menu.scope1.vehicle'],
              icon: 'fa-solid fa-car-side',
              routerLink: ['/dashboard/scope1/vehicles'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: translations['views.app.menu.scope1.fugitiveEmission'],
              icon: 'fa-solid fa-snowflake',
              routerLink: ['/dashboard/scope1/fugitiveEmission'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: translations['views.app.menu.scope1.stationaryDevice'],
              icon: 'fa-solid fa-fire',
              routerLink: ['/dashboard/scope1/stationaryDevice'],
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          label: translations['views.app.menu.scope2'],
          icon: 'fa-solid fa-fan',
          expanded: true,
          items: [
            {
              label: translations['views.app.menu.scope2.heatConsumption'],
              icon: 'fa-solid fa-temperature-high',
              routerLink: ['/dashboard/scope2/heatConsumption'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: translations['views.app.menu.scope2.electricityConsumption'],
              icon: 'fa-solid fa-bolt',
              routerLink: ['/dashboard/scope2/electricity'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: translations['views.app.menu.scope2.renewableEnergyConsumption'],
              icon: 'fa-solid fa-plug',
              routerLink: ['/dashboard/scope2/renewableEnergyConsumption'],
              routerLinkActiveOptions: { exact: true },
            }
          ]
        },
        {
          label: translations['views.app.menu.scope3'],
          icon: 'fa-solid fa-earth-americas',
          expanded: true,
          badge: !this.hasScope3Access() ? 'Wykup dostęp' : '',
          disabled: !this.hasScope3Access() ?? true,
          items: [
            {
              label: translations['views.app.menu.scope3.manualCompaniesCO2Score'],
              disabled: !this.hasScope3Access(),
              tooltip: this.hasScope3Access() ? '' : translations['views.app.menu.no_scope3_access'],
              icon: 'fa-solid fa-house-flag',
              routerLink: ['/dashboard/scope3/manualCompaniesCO2Score'],
              routerLinkActiveOptions: { exact: true }
            },
            {
              label: translations['views.app.menu.scope3.conferences'],
              icon: 'fa-solid fa-house-flag',
              routerLink: ['/dashboard/scope3/conferences'],
              disabled: !this.hasScope3Access() || !this.hasConferenceAccess(),
              tooltip: this.hasConferenceAccess() ? '' : translations['views.app.menu.no_conference_access'],
              routerLinkActiveOptions: { exact: true },
              visible: this.hasConferenceAccess(),
            },
          ]
        },
        
      ];
    });

  }

  private hasConferenceAccess(): boolean {
    return this.user?.token?.resource_access?.[environment.clientId]?.roles?.includes('scope3_conferences_management') ?? false;
  }

  private hasScope3Access(): boolean {
    return this.user?.token?.resource_access?.[environment.clientId]?.roles?.includes('scope3_access') ?? false;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  logout() {
    this.keycloak.logout();
  }

  toggleNightMode() {
    this.themeService.toggleDarkMode();
  }

  

}
