import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../../core/services/companies/models/company.model';
import { select, Store } from '@ngrx/store';
import { selectCompanies, selectLoadingCompanies, selectSelectedCompany, selectYear } from '../../../core/state/company/company-context.selectors';
import { changeSelectedCompany, changeYear } from '../../../core/state/company/company-context.actions';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { faGear } from '@fortawesome/free-solid-svg-icons'; 

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrl: './company-select.component.css'
})
export class CompanySelectComponent implements OnInit,AfterViewInit, OnDestroy {

  companies: Company[] = [];
  loadingCompanies$ = this.store.pipe(select(selectLoadingCompanies));
  selectedCompany$: Observable<Company | null> = this.store.pipe(select(selectSelectedCompany));
  selectedYear$: Observable<number | null> = this.store.pipe(select(selectYear));
  
  availableYears = [2021, 2022, 2023, 2024, 2025].sort((a, b) => a - b);

  form: FormGroup;

  faGear = faGear;

  @ViewChild('resizableBlock') resizableBlock!: ElementRef<HTMLDivElement>;
  private resizeObserver!: ResizeObserver;
  width: number = 0;

  constructor(
    private translate: TranslateService,
    private store: Store
  ) {
    this.form = new FormGroup({
      year: new FormControl(new Date().getFullYear()) 
    });
  }

  ngOnInit() {
    this.store.pipe(select(selectCompanies)).subscribe(companies => {
      this.companies = companies || [];
    });

    this.selectedYear$.subscribe(year => {
      if (year !== null) {
        this.form.get('year')?.setValue(year, { emitEvent: false });
      }
    });
  }

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.width = entry.contentRect.width;
      }
    });

    this.resizeObserver.observe(this.resizableBlock.nativeElement);

  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.resizableBlock.nativeElement);
      this.resizeObserver.disconnect();
    }
  }

  onChangeCompany(event: Company) {
    this.store.dispatch(changeSelectedCompany({ item: event }));
  }

  onConfigureCompanies() {
    //navigate to https://panel.bdomobile.com/companies
    window.open('https://panel.bdomobile.com/companies');
  }

  onChangeYear(event: DropdownChangeEvent) {
    this.store.dispatch(changeYear({ year: event.value }));
  }

}
