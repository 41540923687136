import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { AvatarModule } from 'primeng/avatar';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcumb/breadcrumb.component';
import { MenuModule } from 'primeng/menu';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GenericTableComponent } from '@mobiletract/ngx-generic-table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MenuComponent } from './components/sidebar-menu/menu.component';
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { FormsModule } from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { SplashscreenComponent } from './components/splashscreen/splashscreen.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HubComponent } from './templates/hub/hub.component';
import { ChipModule } from 'primeng/chip';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { AppPanelComponent } from "./components/app-panel/app-panel.component";
import { LanguageButtonComponent } from './components/language-button/language-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({ declarations: [
        NavigationBarComponent,
        HeaderProfileComponent,
        BreadcrumbComponent,
        ErrorDialogComponent,
        SidebarComponent,
        MenuComponent,
        CompanySelectComponent,
        HeaderTitleComponent,
        SplashscreenComponent,
        PageNotFoundComponent,
        HubComponent,
        FooterComponent
    ],
    exports: [
        NavigationBarComponent,
        BreadcrumbComponent,
        ErrorDialogComponent,
        RouterModule,
        MenuComponent,
        CompanySelectComponent,
        HeaderTitleComponent,
        HeaderProfileComponent,
        SplashscreenComponent,
        PageNotFoundComponent,
        FooterComponent
    ], imports: [CommonModule,
        FormsModule,
        RouterModule,
        MenubarModule,
        AvatarModule,
        ButtonModule,
        MenuModule,
        CalendarModule,
        InputNumberModule,
        InputTextModule,
        DropdownModule,
        ProgressSpinnerModule,
        GenericTableComponent,
        DynamicDialogModule,
        TranslateModule,
        SkeletonModule,
        ReactiveFormsModule,
        SidebarModule,
        TooltipModule,
        ButtonModule,
        ChipModule,
        FontAwesomeModule,
        AppPanelComponent,
        LanguageButtonComponent], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule { }
